<template>
  <div>
    <h3>Welcome {{ currentUser }}</h3>
    <v-container>
      <v-row>
        <v-col md="3" xs="12">
          <router-link to="/Gallery">
            <div class="dashboard">
              <h3>Gallery</h3>
            </div>
          </router-link>
        </v-col>
        <v-col md="3" xs="12">
          <router-link to="/Manufacture">
            <div class="dashboard">
              <h3>Infrastructure</h3>
            </div>
          </router-link>
        </v-col>
        <v-col md="3" xs="12">
          <router-link to="/Customers">
            <div class="dashboard">
              <h3>Customer</h3>
            </div>
          </router-link>
        </v-col>
        <v-col md="3" xs="12">
          <router-link to="/Employee">
            <div class="dashboard">
              <h3>Employees</h3>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { fb } from "../firebase";
export default {
  name: "Dashboard",
  data: () => ({
    currentUser: ""
  }),
  created() {
    if (fb.auth().currentUser) {
      this.currentUser = fb.auth().currentUser.email;
    }
  }
};
</script>
<style>
.dashboard {
  margin: 30px 0;
  align-items: center;
  cursor: pointer;
  padding: 50% 40px;
  background-color: rgb(192, 188, 188);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  text-align: center;
  overflow: hidden;
  color: white;
  position: relative;
  display: block;
  height: 320px;
  margin-top: 30px;
  /* transition-delay: 0.1s; */
  transition-duration: 0.5s;
  transition-property: transform;
}

.dashboard:hover {
  color: #fff;
  transform: translateY(-15px);

  cursor: pointer;
}
a {
  color: #ffffff;
  margin-bottom: 15px;
  margin-top: 20px;
  display: block;
  overflow: hidden;
  transition: 1.5s;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
</style>

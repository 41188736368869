<template>
  <div>
    <v-content>
      <v-container>
        <v-file-input
          v-model="image"
          required
          counter
          show-size
          label="Select Image File..."
          accept="image/*"
          @change="uploadImage"
        ></v-file-input>
        <img class="empImg" v-if="imgurl != null" :src="imgurl" alt="" /> <br />
        <v-btn color="primary" @click="onUpload" :disabled="Employee.length > 0"
          >Upload</v-btn
        >
        <h3>Current Image</h3>
        <div v-if="Employee.length > 0">
          <span v-for="(emp, index) in Employee" :key="index">
            <img class="empImg" :src="emp.image" alt="" /> <br />
            <v-btn color="primary" @click="onDelete(emp.image, emp)"
              >Delete <v-icon class="delete">mdi-delete</v-icon></v-btn
            >
          </span>
        </div>
      </v-container>
    </v-content>
    <v-snackbar top v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import { fb, db } from "../firebase.js";

export default {
  name: "Employee",
  data: () => ({
    snackbar: false,
    text: "",
    image: null,
    imgurl: null,
    Employee: [],
    employee: {
      name: null,
      image: null
    }
  }),
  methods: {
    onUpload() {
      console.log(this.image);
      if (this.image != null) {
        var storageRef = fb.storage().ref("Employee/" + this.image.name);
        let uploadTask = storageRef.put(this.image);
        uploadTask.on(
          "state_changed",
          snapshot => {
            console.log(snapshot);
          },
          error => {
            console.log(error);
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              this.employee.image = downloadURL;
              console.log("File available at", downloadURL);
              db.collection("Employee")
                .add(this.employee)
                .catch(function(error) {
                  console.error("Error adding document: ", error);
                });
            });
          }
        );
        this.text = "Employee's image added";
        this.snackbar = true;
        this.image = null;
        this.imgurl = null;
        this.employee.image = null;
        this.employee.name = null;
      } else {
        alert("Add an Image");
      }
    },
    onDelete(img, doc) {
      // console.log(img);
      // console.log(doc);
      let ref = fb.storage().refFromURL(img);
      console.log(ref);
      ref
        .delete()
        .then(function() {
          console.log("image deleted");
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!err
          console.log(error);
          console.log("an error occurred");
        });
      this.$firestore.Employee.doc(doc[".key"])
        .delete()
        .then(() => {
          this.text = "Employee's image deleted";
          this.snackbar = true;
        });
    },
    uploadImage() {
      if (this.image === null) {
        this.imgurl = null;
      }
      this.employee.name = this.image.name;
      this.imgurl = URL.createObjectURL(this.image);
      console.log(this.imgurl);
    }
  },
  firestore() {
    return {
      Employee: db.collection("Employee")
    };
  }
};
</script>
<style lang="scss" scoped>
.v-icon {
  color: #fff !important;
}
.empImg {
  width: 500px;
}
</style>

import { render, staticRenderFns } from "./Employee.vue?vue&type=template&id=506e4c82&scoped=true&"
import script from "./Employee.vue?vue&type=script&lang=js&"
export * from "./Employee.vue?vue&type=script&lang=js&"
import style0 from "./Employee.vue?vue&type=style&index=0&id=506e4c82&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506e4c82",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VContent } from 'vuetify/lib/components/VContent';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VContainer,VContent,VFileInput,VIcon,VSnackbar})

import firebase from "firebase/app";
import "firebase/auth";
require("firebase/firestore");
import "firebase/storage";
var firebaseConfig = {
  apiKey: "AIzaSyAIw5fCPKYSHR82ajr4pFay7oq34EXcMPo",
  authDomain: "mano-tools.firebaseapp.com",
  projectId: "mano-tools",
  storageBucket: "mano-tools.appspot.com",
  messagingSenderId: "891039413597",
  appId: "1:891039413597:web:d3d073577bba5337fd64b4"
};

var fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { fb, db };

import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import { fb } from "../firebase";
import Gallery from "../views/Gallery.vue";
import Employee from "../views/Employee.vue";
import Manufacture from "../views/Manufactures.vue";
import Customers from "../views/Customers.vue";
Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  //eslint-disable-next-line
  return originalPush.call(this, location).catch((error) => {});
};
const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Login",
    name: "Login",
    component: Login
  },
  {
    path: "/Gallery",
    name: "Gallery",
    component: Gallery,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Manufacture",
    name: "Manufacture",
    component: Manufacture,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Customers",
    name: "Customers",
    component: Customers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/Employee",
    name: "Employee",
    component: Employee,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = fb.auth().currentUser;

  if (requiresAuth && !currentUser) {
    next("/Login");
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});
export default router;

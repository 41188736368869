<template>
  <div class="customer">
    <v-container style="margin-top: 10px">
      <h3>Our <span>Customers</span></h3>
      <v-layout justify-center>
        <v-btn class="button" elevation="5" @click="newCustomer" rounded
          >Add Customer</v-btn
        >
      </v-layout>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Add a Customer</span>
            </v-card-title>
            <v-col cols="12" md="12">
              <v-text-field
                label="Name"
                v-model="customer.name"
                :error-messages="nameError"
                required
                @input="$v.customer.name.$touch()"
                @blur="$v.customer.name.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-file-input
                chips
                counter
                show-size
                truncate-length="24"
                v-model="images"
                required
                label="Select Image File..."
                accept="image/*"
                @change="uploadImage"
              ></v-file-input>
              <div class="img">
                <img class="demo" :src="imgurl" alt="" />
              </div>
            </v-col>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click.prevent="addCustomer">
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Customer Name</th>
              <th class="text-left">Image</th>

              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(customer, index) in Customers">
            <tr>
              <td>{{ customer.name }}</td>
              <td>
                <v-avatar>
                  <img width="300px" :src="customer.images" alt="John" />
                </v-avatar>
              </td>
              <td>
                <v-btn icon @click="deleteCustomer(customer)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-snackbar top v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
//eslint-disable-next-line
import { fb, db } from "../firebase.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "add-customers",
  mixins: [validationMixin],
  validations: {
    customer: {
      name: { required }
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      dialog: false,
      imgurl: null,
      images: null,
      Customers: [],
      customer: {
        name: null,
        images: null
      }
    };
  },
  firestore() {
    return {
      Customers: db.collection("Customers")
    };
  },
  methods: {
    newCustomer() {
      this.reset();
      this.dialog = !this.dialog;
    },
    reset() {
      this.customer.name = null;
      this.customer.images = null;
      this.images = null;
      this.imgurl = null;
    },
    addCustomer() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // console.log(this.customer.images.name)
        var storageRef = fb.storage().ref("Customers/" + this.images.name);
        let uploadTask = storageRef.put(this.images);
        uploadTask.on(
          "state_changed",
          snapshot => {
            console.log(snapshot);
          },
          error => {
            console.log(error);
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
              this.customer.images = downloadURL;
              console.log("File available at", downloadURL);
              db.collection("Customers")
                .add(this.customer)
                .catch(function(error) {
                  console.error("Error adding document: ", error);
                });
            });
          }
        );

        //logic
        this.dialog = false;
        this.text = "Client logo added";
        this.snackbar = true;
      } else {
        console.log("not");
      }
    },

    deleteCustomer(doc) {
      let image = fb.storage().refFromURL(doc.images);
      console.log(image);
      image
        .delete()
        .then(function() {
          console.log("image deleted");
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!err
          console.log(error);
          console.log("an error occurred");
        });

      this.$firestore.Customers.doc(doc[".key"])
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.text = "Customer successfully deleted!";
          this.snackbar = true;
        })
        .catch(error => {
          console.error("Error removing document: ", error);
          this.text = "Error Removing Customer";
          this.snackbar = true;
        });
      // console.log(doc['.key'])
    },
    uploadImage() {
      // let file = e.target.files[0];
      if (this.images === null) {
        this.imgurl = null;
        this.customer.images = null;
      }
      this.imgurl = URL.createObjectURL(this.images);
      console.log(this.imgurl);
    }
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) return errors;
      !this.$v.customer.name.required && errors.push("Name is required.");
      return errors;
    }
  },
  watch: {
    // customer: {
    //   //eslint-disable-next-line
    //   handler(val, oldVal) {
    //     if (this.images === null) {
    //       console.log("dsa nul")
    //       // this.imgurl = "";
    //       // this.customer.images = "";
    //     }
    //   },
    //   deep: true,
    // },
  }
};
</script>

<style>
span.delete-img {
  position: absolute;
}
i {
  color: #040674;
}
span.delete-img:hover {
  cursor: pointer;
  color: #f5a623;
}
.demo {
  width: auto;
  height: 200px;
}
@media only screen and (max-width: 450px) {
  .demo {
    height: 100px;
  }
}
</style>

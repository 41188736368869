<template>
  <div>
    <v-container style="margin-top:10%;">
      <h2 style="text-align:center; color: #050674 ">SIGN IN</h2>
      <form>
        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          label="E-mail"
          required
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
        ></v-text-field>
        <v-text-field
          :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
          @input="$v.password.$touch()"
          :error-messages="passwordErrors"
          @blur="$v.password.$touch()"
          :type="show3 ? 'text' : 'password'"
          name="input-10-2"
          label="Password"
          v-model="password"
          class="input-group--focused"
          v-on:keyup.enter="login"
          @click:append="show3 = !show3"
        ></v-text-field>
        <v-btn primary @click="login" :loading="!loading">
          submit
        </v-btn>
      </form>
    </v-container>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { fb } from "../firebase.js";
export default {
  name: "Login",
  mixins: [validationMixin],

  validations: {
    password: { required },
    email: { required, email }
  },

  data() {
    return {
      password: null,
      email: null,
      show3: false,
      loading: true
    };
  },
  methods: {
    login() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        //logic
        this.loading = false;
        fb.auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            // this.$router.replace("/");
            location.replace("/");
            // console.log("Sucess");
            // console.log(fb.auth().currentUser.email);
          })
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === "auth/wrong-password") {
              alert("wrong pass");
            } else {
              alert(errorMessage);
            }
            console.log(errorMessage);
          });
      } else {
        console.log("not");
      }
    }
  },

  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Name is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    }
  }
};
</script>
<style></style>

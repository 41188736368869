<template>
  <div class="Manufactures">
    <v-container style="margin-top: 10px">
      <h3>List of <span> Machines</span></h3>
      <v-layout justify-center>
        <v-btn class="button" elevation="5" @click="newMachine" rounded
          >Add a New Machine</v-btn
        >
      </v-layout>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span v-if="popup == 'add'" class="headline"
                >Add Machine details</span
              >
              <span v-if="popup == 'edit'" class="headline"
                >Edit Machine details</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="Type of Machine*"
                      v-model="machine.type"
                      :error-messages="typeError"
                      required
                      @input="$v.machine.type.$touch()"
                      @blur="$v.machine.type.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Machine made*"
                      v-model="machine.made"
                      :error-messages="madeError"
                      required
                      @input="$v.machine.made.$touch()"
                      @blur="$v.machine.made.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Model*"
                      v-model="machine.model"
                      :error-messages="modelError"
                      required
                      @input="$v.machine.model.$touch()"
                      @blur="$v.machine.model.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Specification*"
                      v-model="machine.specification"
                      :error-messages="specificationError"
                      required
                      @input="$v.machine.specification.$touch()"
                      @blur="$v.machine.specification.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      label="Accuracy*"
                      v-model="machine.accuracy"
                      :error-messages="accuracy"
                      required
                      @input="$v.machine.accuracy.$touch()"
                      @blur="$v.machine.accuracy.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="machine.table"
                      :error-messages="tableError"
                      required
                      @input="$v.machine.table.$touch()"
                      @blur="$v.machine.table.$touch()"
                      :items="['Table1', 'Table2']"
                      label="Table*"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions v-if="popup == 'add'">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click.prevent="addMachine">
                Add
              </v-btn>
            </v-card-actions>
            <v-card-actions v-if="popup == 'edit'">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click.prevent="updateMachine">
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-row style="margin-top: 5%" justify="center">
        <h3><span>Table 1</span></h3>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Machine Type</th>
              <th class="text-left">Make</th>
              <th class="text-left">Model</th>
              <th class="text-left">Specification</th>
              <th class="text-left">Accuracy</th>
              <th class="text-left">Edit</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(machine, index) in Machines">
            <tr v-if="machine.table == 'Table1'">
              <td>{{ machine.type }}</td>
              <td>{{ machine.made }}</td>
              <td>{{ machine.model }}</td>
              <td>{{ machine.specification }}</td>
              <td>{{ machine.accuracy }}</td>
              <td>
                <v-btn icon @click="editMachine(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn icon @click="deleteProduct(machine)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row style="margin-top: 5%" justify="center">
        <h3><span>Table 2</span></h3>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Machine Type</th>
              <th class="text-left">Make</th>
              <th class="text-left">Model</th>
              <th class="text-left">Specification</th>
              <th class="text-left">Accuracy</th>
              <th class="text-left">Edit</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(machine, index) in Machines">
            <tr v-if="machine.table == 'Table2'">
              <td>{{ machine.type }}</td>
              <td>{{ machine.made }}</td>
              <td>{{ machine.model }}</td>
              <td>{{ machine.specification }}</td>
              <td>{{ machine.accuracy }}</td>
              <td>
                <v-btn icon @click="editMachine(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn icon @click="deleteProduct(machine)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-snackbar top v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, decimal } from "vuelidate/lib/validators";
import { db } from "../firebase.js";
export default {
  name: "Manufactures",
  mixins: [validationMixin],

  validations: {
    machine: {
      type: { required },
      made: { required },
      model: { required },
      specification: { required },
      accuracy: { required, decimal },
      table: { required }
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      dialog: false,
      popup: "",
      Machines: [],
      machine: {
        type: null,
        made: null,
        model: null,
        specification: null,
        accuracy: null,
        table: null
      }
    };
  },
  firestore() {
    return {
      Machines: db.collection("Machines")
    };
  },
  methods: {
    newMachine() {
      this.popup = "add";
      this.reset();
      this.dialog = !this.dialog;
    },
    reset() {
      this.machine.type = null;
      this.machine.made = null;
      this.machine.specification = null;
      this.machine.accuracy = null;
      this.machine.table = null;
      this.machine.model = null;
    },
    addMachine() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        db.collection("Machines")
          .add(this.machine)
          .catch(function(error) {
            console.error("Error adding document: ", error);
          });
        //logic
        this.dialog = false;
        this.reset();
        this.text = "Details Added to table";
        this.snackbar = true;
      } else {
        console.log("not");
      }
    },
    editMachine(i) {
      this.popup = "edit";
      this.machine = this.Machines[i];
      this.dialog = !this.dialog;
    },
    updateMachine() {
      let id = this.machine[".key"];
      db.collection("Machines")
        .doc(id)
        .update({
          type: this.machine.type,
          made: this.machine.made,
          specification: this.machine.specification,
          accuracy: this.machine.accuracy,
          table: this.machine.table,
          model: this.machine.model
        })
        .then(() => {
          this.dialog = !this.dialog;
          this.text = "Machine updated";
          this.snackbar = true;
        });
    },
    deleteProduct(doc) {
      this.$firestore.Machines.doc(doc[".key"])
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.text = "Machine successfully deleted!";
          this.snackbar = true;
        })
        .catch(error => {
          console.error("Error removing document: ", error);
        });
      // console.log(doc['.key'])
    }
  },
  computed: {
    typeError() {
      const errors = [];
      if (!this.$v.machine.type.$dirty) return errors;
      !this.$v.machine.type.required && errors.push("Type is required.");
      return errors;
    },
    madeError() {
      const errors = [];
      if (!this.$v.machine.made.$dirty) return errors;
      !this.$v.machine.made.required && errors.push("Made is required.");
      return errors;
    },
    modelError() {
      const errors = [];
      if (!this.$v.machine.model.$dirty) return errors;
      !this.$v.machine.model.required && errors.push("Model is required.");
      return errors;
    },
    specificationError() {
      const errors = [];
      if (!this.$v.machine.specification.$dirty) return errors;
      !this.$v.machine.specification.required &&
        errors.push("Specification is required.");
      return errors;
    },
    accuracy() {
      const errors = [];
      if (!this.$v.machine.accuracy.$dirty) return errors;
      (!this.$v.machine.accuracy.required &&
        errors.push("Accuracy is required.")) ||
        (!this.$v.machine.accuracy.decimal &&
          errors.push("Number is required."));
      return errors;
    },
    tableError() {
      const errors = [];
      if (!this.$v.machine.table.$dirty) return errors;
      !this.$v.machine.table.required && errors.push("Table is required.");
      return errors;
    }
  }
};
</script>
<style lang="css">
h3 {
  color: #050674;
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}
h3 span {
  font-weight: 900 !important;
}
.button {
  background-color: #050674 !important;
  color: white !important;
}
@media screen and (max-width: 750px) {
  .image {
    display: none;
  }
}
</style>

<template>
  <div class="gallery">
    <v-container style="margin-top: 10px">
      <h3>Gallery</h3>
      <v-layout justify-center>
        <v-btn class="button" elevation="5" @click="newImage" rounded
          >Add a Gallery Image</v-btn
        >
      </v-layout>

      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span v-if="popup == 'edit'" class="headline"
                >Edit Gallery Image</span
              >
              <span v-if="popup == 'add'" class="headline"
                >Add Gallery Image</span
              >
            </v-card-title>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" sm="6">
                  <v-text-field
                    label="Name"
                    v-model="gallery.name"
                    :error-messages="nameError"
                    required
                    @input="$v.gallery.name.$touch()"
                    @blur="$v.gallery.name.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <v-select
                    v-model="gallery.category"
                    :error-messages="categoryError"
                    required
                    @input="$v.gallery.category.$touch()"
                    @blur="$v.gallery.category.$touch()"
                    :items="['Infrastructure', 'Products', 'Celebrations']"
                    label="Category"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-file-input
                    chips
                    v-if="popup == 'add'"
                    counter
                    show-size
                    truncate-length="24"
                    v-model="images"
                    required
                    label="Select Image File..."
                    accept="image/*"
                    @change="uploadImage"
                  ></v-file-input>
                  <div class="img">
                    <span v-if="popup == 'edit'">
                      <img class="demo" :src="gallery.images" alt="" /> <br />
                    </span>
                    <span v-if="popup == 'add'">
                      <img class="demo" :src="imgurl" alt="" />
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn
                v-if="popup == 'edit'"
                color="blue darken-1"
                text
                @click.prevent="updateGallery"
              >
                Update
              </v-btn>
              <v-btn
                color="blue darken-1"
                v-if="popup == 'add'"
                text
                @click.prevent="addImage"
              >
                Add
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <v-simple-table style="margin-top: 4%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Image</th>
              <th class="text-left">Category</th>
              <th class="text-left">Edit</th>
              <th class="text-left">Delete</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(gallery, index) in Gallerys">
            <tr>
              <td>{{ gallery.name }}</td>
              <td>
                <v-avatar>
                  <img width="300px" :src="gallery.images" alt="John" />
                </v-avatar>
              </td>
              <td>{{ gallery.category }}</td>
              <td>
                <v-btn icon @click="editGallery(index)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
              <td>
                <v-btn icon @click="deleteGallery(gallery)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>

    <v-snackbar top v-model="snackbar">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
//eslint-disable-next-line
import { fb, db } from "../firebase.js";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Gallery",
  mixins: [validationMixin],
  validations: {
    gallery: {
      name: { required },
      category: { required }
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      popup: "",
      dialog: false,
      imgurl: null,
      images: null,
      Gallerys: [],
      gallery: {
        name: null,
        category: null,
        images: null
      }
    };
  },
  firestore() {
    return {
      Gallerys: db.collection("Gallerys")
    };
  },
  methods: {
    newImage() {
      this.reset();
      this.popup = "add";
      this.dialog = !this.dialog;
    },
    reset() {
      this.gallery.name = null;
      this.gallery.images = null;
      this.gallery.category = null;
      this.images = null;
      this.imgurl = null;
    },
    addImage() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // console.log(this.customer.images.name)
        if (this.images != null) {
          var storageRef = fb.storage().ref("Gallery/" + this.images.name);
          let uploadTask = storageRef.put(this.images);
          uploadTask.on(
            "state_changed",
            snapshot => {
              console.log(snapshot);
            },
            error => {
              console.log(error);
              // Handle unsuccessful uploads
            },
            () => {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                this.gallery.images = downloadURL;
                console.log("File available at", downloadURL);
                db.collection("Gallerys")
                  .add(this.gallery)
                  .catch(function(error) {
                    console.error("Error adding document: ", error);
                  });
              });
            }
          );
          //logic
          this.dialog = false;
          this.text = "Image added";
          this.snackbar = true;
        } else {
          alert("Add Image");
        }
      } else {
        console.log("not");
      }
    },
    editGallery(i) {
      this.popup = "edit";
      this.gallery = this.Gallerys[i];
      this.dialog = !this.dialog;
    },
    updateGallery() {
      // this.$firestore.Gallerys.doc(this.gallery[".key"]).update(this.gallery);
      let id = this.gallery[".key"];
      // console.log(id)
      db.collection("Gallerys")
        .doc(id)
        .update({
          name: this.gallery.name,
          category: this.gallery.category
        })
        .then(() => {
          this.dialog = !this.dialog;
          this.text = "Image updated";
          this.snackbar = true;
        });
    },
    deleteGallery(doc) {
      let image = fb.storage().refFromURL(doc.images);
      console.log(image);
      image
        .delete()
        .then(function() {
          console.log("image deleted");
        })
        .catch(function(error) {
          // Uh-oh, an error occurred!err
          console.log(error);
          console.log("an error occurred");
        });
      this.$firestore.Gallerys.doc(doc[".key"])
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          this.text = "Image successfully deleted!";
          this.snackbar = true;
        })
        .catch(error => {
          console.error("Error removing document: ", error);
          this.text = "Error Removing Customer";
          this.snackbar = true;
        });
      // console.log(doc['.key'])
    },
    uploadImage() {
      // let file = e.target.files[0];
      if (this.images === null) {
        this.imgurl = null;
      }
      this.imgurl = URL.createObjectURL(this.images);
      console.log(this.imgurl);
    }
  },
  computed: {
    nameError() {
      const errors = [];
      if (!this.$v.gallery.name.$dirty) return errors;
      !this.$v.gallery.name.required && errors.push("Name is required.");
      return errors;
    },
    categoryError() {
      const errors = [];
      if (!this.$v.gallery.category.$dirty) return errors;
      !this.$v.gallery.category.required && errors.push("Table is required.");
      return errors;
    }
  },
  watch: {
    // customer: {
    //   //eslint-disable-next-line
    //   handler(val, oldVal) {
    //     if (this.images === null) {
    //       console.log("dsa nul")
    //       // this.imgurl = "";
    //       // this.customer.images = "";
    //     }
    //   },
    //   deep: true,
    // },
  }
};
</script>

<style>
span.delete-img {
  position: absolute;
}
i {
  color: #040674;
}
span.delete-img:hover {
  cursor: pointer;
  color: #f5a623;
}
.demo {
  width: auto;
  height: 200px;
}
@media only screen and (max-width: 450px) {
  .demo {
    height: 100px;
  }
}
</style>

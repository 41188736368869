import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueFirestore from "vue-firestore";
import { fb } from "./firebase";
Vue.config.productionTip = false;
Vue.use(VueFirestore);
Vue.use(VueFirestore, {
  key: "id", // the name of the property. Default is '.key'.
  enumerable: true //  whether it is enumerable or not. Default is true.
});
let app = "";
// eslint-disable-next-line
fb.auth().onAuthStateChanged(function(user)
{
  if (!app) {
    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }
});

<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title" style="color:#050674">
            Mano Tools
          </v-list-item-title>
          <v-list-item-subtitle>
            Menu
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          @click="$router.push({ path: '/' })"
          link
          v-if="isLoggedIn === true"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push({ path: '/Gallery' })"
          link
          v-if="isLoggedIn === true"
        >
          <v-list-item-icon>
            <v-icon>mdi-image</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Gallery</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push({ path: '/Manufacture' })"
          link
          v-if="isLoggedIn === true"
        >
          <v-list-item-icon>
            <v-icon>mdi-screw-machine-round-top</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Infrastructure</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push({ path: '/Employee' })"
          link
          v-if="isLoggedIn === true"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Employees</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push({ path: '/Customers' })"
          link
          v-if="isLoggedIn === true"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-check</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="$router.push({ path: '/Login' })"
          link
          v-if="isLoggedIn === false"
        >
          <v-list-item-icon>
            <v-icon>mdi-login</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append v-if="isLoggedIn === true">
        <div class="pa-2">
          <v-btn class="buttons" :loading="!loading" @click="logout()" block>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-click-outside="onClickOutside"
      ></v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title>
        <router-link to="/">
          <img src="../assets/logo.png" alt="Mano Tools Logo" width="110px"
        /></router-link>
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>
  </div>
</template>
<script>
import { fb } from "../firebase";
export default {
  name: "Navigation",
  data: () => ({
    drawer: false,
    right: null,
    currentUser: "",
    isLoggedIn: false,
    loading: true
  }),

  created() {
    if (fb.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUser = fb.auth().currentUser.email;
    }
  },
  methods: {
    onClickOutside() {
      this.drawer = false;
    },
    logout() {
      this.loading = false;
      fb.auth()
        .signOut()
        .then(() => {
          console.log("logedout");
          // this.$router.replace("/Login");
          location.replace("/Login");
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style>
i {
  color: #050674 !important;
}
.buttons {
  background-color: #050674 !important;
  color: white !important;
}
</style>
